import {FC,} from 'react'
import {Route, Routes} from 'react-router-dom'
import PublicPage from '../modules/public/Index'

export const PublicRoutes: FC = () => {
  return (
    <Routes>
      <Route path='/*' element={<PublicPage />} />
    </Routes>
  )
}
