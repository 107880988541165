import {Outlet, useNavigate} from 'react-router-dom'
import {useAuth} from '../../auth'
import {FC, Fragment, useEffect, useState} from 'react'
import {Col, Container, Modal, Nav, Navbar, Row} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Auth} from '../components/Auth'
import '../scss/_index.scss'
import {ConditinalRendering} from 'src/app/widgets/molecule/conditional-rendering'
import DaftarNotaris from '../components/daftar-notaris'
import NavbarNavigationSm from './navbar-navigation-sm'
import CekPembayaranPbb from '../components/cek-pembayaran-pbb'

/**
 * Portal Pajak   Page
 *
 * @returns {React.ReactNode}
 */
const NavbarNavigation: FC = () => {
  const {auth} = useAuth()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showCekPembayaranPbb, setShowCekPembayaranPbb] = useState<boolean>(false)
  const [isShowModalNotaris, setIsModalNotaris] = useState<boolean>(false)

  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  useEffect(() => {
    if (auth) navigate('/dashboard')
  }, [auth, navigate])

  return (
    <ConditinalRendering
      conditional={!auth}
      onTrueComponent={
        <Fragment>
          <Navbar
            fixed='top'
            style={{
              height: '10vh',
              background: 'rgba(0, 0, 0, 0.31)',
              backdropFilter: 'blur(12px)',
            }}
          >
            <Container>
              <Navbar.Brand className='fw-bold d-flex align-items-center' href='/'>
                <img
                  src={toAbsoluteUrl('/media/home/logo-binjai.png')}
                  alt='logo-binjai'
                  width={40}
                  className='me-3'
                />
                <div className='d-flex flex-column'>
                  <span className='brand-title'>BINJAI</span>
                  <span className='brand-sub-title'>PORTAL PAJAK BPKPD BINJAI</span>
                </div>
              </Navbar.Brand>
              <Nav className='hidden md:flex justify-content-end align-items-center'>
                <Nav.Link
                  className='text-light py-3'
                  href='#'
                  onClick={() => setShowCekPembayaranPbb(true)}
                >
                  Cek Pembayaran PBB
                </Nav.Link>
                <Nav.Link
                  className='text-light py-3'
                  href='#'
                >
                  |
                </Nav.Link>
                <Nav.Link
                  className='text-light py-3'
                  href='#'
                  onClick={() => setIsModalNotaris(true)}
                >
                  Daftar Notaris
                </Nav.Link>
                <Nav.Link
                  className='text-light py-3'
                  href='#'
                >
                  |
                </Nav.Link>
                <Nav.Link className='text-light mr-5 py-3' href='/realisasi'>
                  Realisasi
                </Nav.Link>
                <Nav.Link
                  className='text-light px-10 py-3'
                  style={{
                    borderRadius: '56px',
                    background: '#00923F',
                  }}
                  href='#'
                  onClick={() => setShowModal(true)}
                >
                  Masuk
                </Nav.Link>
              </Nav>
              <NavbarNavigationSm
                toggleDaftarNotaris={() => setIsModalNotaris(true)}
                toggleMasuk={() => setShowModal(true)}
                toggleCekPembayaranPbb={() => setShowCekPembayaranPbb(true)}
                toggleRealisasi={() => setIsModalNotaris(true)}
              />
            </Container>


            <DaftarNotaris
              isShowModalNotaris={isShowModalNotaris}
              setIsModalNotaris={setIsModalNotaris}
            />
            <CekPembayaranPbb
              isOpen={showCekPembayaranPbb}
              isOpenToggle={() => setShowCekPembayaranPbb((prev) => !prev)}
            />




            {/* auth */}
            <Modal
              show={showModal}
              onHide={() => setShowModal(false)}
              backdrop='static'
              centered
              size='lg'
            >
              <Row className='overflow-hidden p-2'>
                <Col className='p-5 m-5'>
                  <span className='d-flex flex-column text-gray-800 lh-sm'>
                    <span className='h1 text-dark fw-bold'>Selamat Datang!</span>
                  </span>
                  <Auth
                    onTab={() => setShowModal(false)}
                    className='d-flex mt-5 mt-sm-0 flex-column  justify-content-center h-md-75'
                  />
                </Col>
                <Col
                  sm={1}
                  md={6}
                  className='d-none d-md-flex justify-content-center align-items-center bg-blue-300 mr-3 overflow-hidden'
                >
                  <div className='c-subscribe-box u-align-center'>
                    <div className='rainbow'>
                      <span></span>
                      <span></span>
                    </div>
                    <div className='c-subscribe-box__wrapper bg-white flex justify-center align-items-center'>
                      <img
                        className='object-fit-cover w-[120px]'
                        src={toAbsoluteUrl('/media/home/logo-binjai.png')}
                        alt='logo binjai'
                      />
                      <h2 className='text-3xl font-bold'>BPKPD KOTA BINJAI</h2>
                    </div>
                  </div>
                </Col>
              </Row>
            </Modal>
          </Navbar>{' '}
          <div className='position-relative overflow-hidden'>
            <Outlet />
          </div>
        </Fragment>
      }
    />
  )
}

export {NavbarNavigation}
