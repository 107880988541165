/**
 * Text Component
 * 
 * The `Text` component is a customizable text rendering component that utilizes the `class-variance-authority` library
 * to apply different text styles. It allows you to define various text variants and apply them to a `<span>` element.
 * 
 * @module Text
 */

import React, { FC, forwardRef } from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import { cn } from 'src/app/helpers/index';

/**
 * Define text variants using `cva` function from `class-variance-authority`.
 */
const textVariants = cva('text-[#212B36]', {
  variants: {
    variant: {
      subtitle1: 'text-base font-semibold',
      subtitle2: 'text-sm font-semibold',
      body1: 'text-base font-medium',
      body2: 'text-sm font-medium',
      caption: 'text-xs font-medium',
      overline: 'text-xs font-bold tracking-widest',
    },
  },
  defaultVariants: {
    variant: 'body2',
  },
});

/**
 * Props for the `Text` component.
 */
export interface TextProps
  extends React.LabelHTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof textVariants> {}

/**
 * `Text` Component
 * 
 * The `Text` component renders customizable text within a `<span>` element, applying the specified text variant's styling
 * using the `class-variance-authority` library.
 * 
 * @example
 * ```jsx
 * <Text variant="subtitle1" className="custom-class">
 *   Hello, world!
 * </Text>
 * ```
 * 
 * @component
 */
export const Text: FC<TextProps> = forwardRef<HTMLSpanElement, TextProps>(
  ({ variant, className, children, ...props }, ref) => {
    // Generate class names using `textVariants` and `cn` helper function.
    const classNames = cn(textVariants({ variant }), className);

    return (
      <span ref={ref} className={classNames} {...props}>
        {children}
      </span>
    );
  }
);

/**
 * Default export of the `Text` component.
 */
export default Text;