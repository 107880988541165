import { IMenu } from "../_menu-config";

export const WajibPajak: IMenu[] = [
  {
    subItems: [
      {
        title: 'Wajib Pajak ',
        guards: ['kecamatan', 'kelurahan'],
        link: 'applications/data-rekap-wajib-pajak/wajib-pajak',
        fontIcon: 'duotone',
        icon: 'people',
      },
    ],
  },
]
