
import { ADJUSTMENT_SERVICE_MENU } from '../_data'
import { IMenu } from '../_menu-config'

export const Dashboard: IMenu[] = [
  {
    subItems: [
      {
        title: 'Dashboard',
        link: '/dashboard',
        fontIcon: 'bi-app-indicator',
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP, ADJUSTMENT_SERVICE_MENU.BPHTB],
        icon: 'element-11',
        calculation: (data: any) => {
          let showRole: string = data

          if (data.role === 'kecamatan') {
            showRole = `${data} - ${data.nm_kecamatan}`
          }

          if (data.role === 'kelurahan') {
            showRole = `${data} - ${data.nm_kelurahan}`
          }

          return `Dashboard \n (${showRole})`
        },
      },
    ],
  },
]

