import {Variants} from 'framer-motion'
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {motion} from 'framer-motion'
import Div from 'src/app/widgets/atomic/div'

interface MobileNavLinkProps {
  title: string
  onClick?: () => void
}

const mobileLinkVars: Variants = {
  initial: {
    y: '30vh',
    transition: {
      duration: 0.5,
    },
  },
  open: {
    y: 0,
    transition: {
      duration: 0.7,
    },
  },
}

const MobileNavLink: FC<MobileNavLinkProps> = ({title, onClick}) => {
  return (
    <Div className='overflow-hidden my-5'>
      <motion.div variants={mobileLinkVars} initial='initial' animate='open'>
        <Link to='#' onClick={onClick} className='text-4xl'>
          {title}
        </Link>
      </motion.div>
    </Div>
  )
}

export default MobileNavLink
