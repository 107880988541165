/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes} from 'react-router-dom'
import {NavbarNavigation} from './layout/navbar-navigation'
import {HomePage} from './page/home-page'
import {RealisasiPage} from './page/realisasi-page'
import {Footer} from './layout/footer'
import CekPembayaranPBB from './page/cek-pembayaran-pbb'

const PublicPage = () => {
  return (
    <Routes>
      <Route path='realisasi' element={<Footer />}>
        <Route index element={<RealisasiPage />} />
      </Route>
      <Route path='cek-pembayaran-pbb' >
        <Route index element={<CekPembayaranPBB />} />
      </Route>
      <Route element={<NavbarNavigation />}>
        <Route path='*' element={<HomePage />} />
      </Route>
    </Routes>
  )
}

export default PublicPage
