/* eslint-disable jsx-a11y/heading-has-content */
import {FC, Fragment, useState} from 'react'
import {Nav, Navbar} from 'react-bootstrap'
import Div from 'src/app/widgets/atomic/div'
import Headline from 'src/app/widgets/atomic/headline'
import Text from 'src/app/widgets/atomic/text'
import MobileNavLink from '../components/mobile-nav-link'
import {ConditinalRendering} from 'src/app/widgets/molecule/conditional-rendering'
import { Variants, motion } from 'framer-motion'



interface NavbarNavigationSmProps {
  toggleDaftarNotaris: () => void
  toggleRealisasi: () => void
  toggleMasuk: () => void
  toggleCekPembayaranPbb: () => void
}



const menuVars: Variants = {
  initial: {
    scaleY: 0, 
  },
  animate: {
    scaleY: 1,
    transition: {
      duration: 0.5,
      ease: [0.12, 0, 0.39, 0]
    }
  },
  exit: {
    scaleY: 0,
    transition: {
      duration: 0.5,
      ease: [0.12, 0, 0.39, 0]
    }
  }
}


const NavbarNavigationSm: FC<NavbarNavigationSmProps> = ({ toggleDaftarNotaris, toggleMasuk, toggleRealisasi, toggleCekPembayaranPbb }) => {
  const [open, setOpen] = useState<boolean>(false)
  const toggleMenu = () => {
    setOpen((prev) => !prev)
  }


  const toggleHandler = (menu: 'daftar notaris' | 'realisasi' | 'masuk' | 'cek pembayaran pbb') => {
    switch (menu) {
      case 'cek pembayaran pbb':
        toggleCekPembayaranPbb()
        break
      case 'daftar notaris':
        toggleDaftarNotaris()
        break
      case 'realisasi':
        toggleRealisasi()
        break
      case 'masuk':
        toggleMasuk()
        break
    }

    toggleMenu()
  }


  return (
    <Fragment>
      <Navbar.Brand className='block md:hidden'>
        <Nav.Link onClick={toggleMenu}>
          <i className='fa-solid fa-bars'></i>
        </Nav.Link>
      </Navbar.Brand>
      <ConditinalRendering
        conditional={open}
        onTrueComponent={
          <motion.div
            variants={menuVars}
            initial="initial"
            animate="animate"
            exit="exit"
            className='fixed left-0 top-0 w-full h-screen origin-top bg-gray-700 text-white p-10'
          >
            <Div className='flex h-full flex-col'>
              <Div className='flex justify-between'>
                <Headline className='text-lg text-white'>Menu</Headline>
                <Text className='cursor-pointer text-md text-white' onClick={toggleMenu}>
                  Tutup
                </Text>
              </Div>
              <Div className='flex flex-col h-100 items-center justify-center'>
                <MobileNavLink onClick={() => toggleHandler('cek pembayaran pbb')} title='Cek Pembayaran PBB' />
                <MobileNavLink onClick={() => toggleHandler('daftar notaris')} title='Daftar Notaris' />
                <MobileNavLink onClick={() => toggleHandler('realisasi')} title='Realisasi' />
                <MobileNavLink onClick={() => toggleHandler('masuk')} title='Masuk' />
              </Div>
            </Div>
          </motion.div>
        }
      />
    </Fragment>
  )
}

export default NavbarNavigationSm
