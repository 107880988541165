import {Form, Formik} from 'formik'
import {Dispatch, SetStateAction} from 'react'
import {Modal, Row} from 'react-bootstrap'
import {EForm} from 'src/app/widgets/EForm/Index'
import {Button} from 'src/app/widgets/atomic/buttom'
import { Devider } from 'src/app/widgets/atomic/devider'
import Div from 'src/app/widgets/atomic/div'
import Text from 'src/app/widgets/atomic/text'
import { ValidatorType, validator, validatorInit } from './validation'
import { AppLoading } from 'src/app/helpers/app-loading'
import { Authentication } from 'src/app/services/authentication/service'
import Swal from 'sweetalert2'
import { StringHelpers } from 'src/app/helpers/string-helpers'



interface DaftarNotarisProps {
  isShowModalNotaris: boolean
  setIsModalNotaris: Dispatch<SetStateAction<boolean>>
}



const showSwal = (isSucces: boolean = true) => {

  Swal.fire({
    icon: isSucces ? 'success' : 'error',
    text: isSucces
            ? 'Data registrasi notaris berhasil ditambah.'
            : 'Data gagal diproses.'
  })

}



const DaftarNotaris: React.FC<DaftarNotarisProps> = ({isShowModalNotaris, setIsModalNotaris}) => {


  const onSubmitHandler = (value: ValidatorType) => {
    AppLoading.showPageLoading()

    const phoneNumber = value.no_hp.split('.').join('')
    Authentication.registerNotaris({...value, kontak_person: phoneNumber, no_hp: phoneNumber })
      .then(_ => showSwal())
      .catch((e) => {
        if (e.response.status === 422) {
          const msg = e?.response?.data?.error ? e?.response?.data?.error : e?.response?.data
          Swal.fire({ 
            icon: 'error', 
            html: `
              <span style="white-space: pre-line">
                ${StringHelpers.errorMessageRebuild(msg)}
              </span>
            ` 
          })
        } else showSwal(false)
      })
      .finally(() => {
        AppLoading.hidePageLoading()
        setIsModalNotaris(false)
      })
  }


  return (
    <Modal
      show={isShowModalNotaris}
      onHide={() => setIsModalNotaris(false)}
      backdrop='static'
      centered
    >
      <Modal.Body>
        <Text className='text-xl'>Daftar Notaris</Text>
        <Devider/>
        <Formik validationSchema={validator} initialValues={validatorInit} onSubmit={onSubmitHandler}>
          <Form placeholder={''}>
            <Div className='w-100'>
              <EForm as='text' name='name' label='Nama' />
              <EForm as='text' name='kota' label='Kota' />
              <Row md={2}>
                <EForm as='password' name='password' label='Password' />
                <EForm as='text' name='no_hp' mask='9999.9999.9999' label='Nomor HP' />
              </Row>
              <EForm as='textarea' name='alamat' label='Alamat' />
              <Div className='w-100 text-end mt-5'>
                <Button
                  type='reset'
                  onClick={() => setIsModalNotaris(false)}
                  className='group border-[1px] border-blue-500 bg-slate-50 text-blue-500 h-100 hover:bg-blue-500 hover:text-white mx-2'
                >
                  Batal
                </Button>
                <Button type='submit' className='group border-[1px] border-blue-500 bg-blue-500 text-white h-100 hover:bg-blue-600 hover:text-white'>
                  Kirim
                </Button>
              </Div>
            </Div>
          </Form>
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default DaftarNotaris
