import { ADJUSTMENT_SERVICE_MENU } from '../_data'
import { IMenu } from '../_menu-config'

export const ApplicationsBphtb: IMenu[] = [
  {

    guards: ['superadmin', 'notaris', 'kabid', 'admin', 'operator', 'kasubbid'],
    guardSubBidang: ['pendataan & penetapan'],
    subItems: [
      {
        title: 'Daftar SPPD',
        guards: ["operator"],
        link: 'applications/application-list',
        fontIcon: 'duotone',
        adjustment: [ADJUSTMENT_SERVICE_MENU.BPHTB],
        icon: 'scroll',
        guardSubBidang: ['pendataan & penetapan'],
        subItems: [
          {
            title: 'Permohonan Masuk',
            // rightTextCodeEl: 'DPMP',
            link: 'applications/daftar-sppd/permohonan-masuk',
          },
          {
            title: 'Yang Diverifikasi',
            guards: ["operator"],
            guardBidang: ['pendataan & penetapan'],
            // rightTextCodeEl: 'DPPDP',
            link: 'applications/daftar-sppd/selesai',
          },
          {
            title: 'Yang Divalidasi',
            guards: ["operator"],
            guardBidang: ['pendataan & penetapan'],
            // rightTextCodeEl: 'DPD',
            link: 'applications/daftar-sppd/validasi',
          },
          {
            title: 'Semua SSPD',
            guards: ["operator"],
            link: 'transaction/sspd/daftar-sppd',
          },
          {
            title: 'SSPD ke BPN',
            guards: ["operator"],
            link: 'transaction/sspd/sspd-ke-bpn',
          },
        ],
      },
      {
        title: 'List Pembatalan SSPD',
        guards: ['notaris', 'superadmin', 'kabid', 'admin', 'operator'],
        fontIcon: 'duotone',
        guardBidang: ['admin_bidang_empty'],
        icon: 'eraser',
        link: 'applications/pembatalan-sppd'
      },

      {
        title: 'Query',
        guards: ['superadmin', 'admin'],
        guardSubBidang: ['pendataan & penetapan'],
        fontIcon: 'duotone',
        guardBidang: ['admin_bidang_empty'],
        icon: 'questionnaire-tablet',
        link: 'applications/query',
        subItems: [
          {
            title: 'Pembayaran PBB-P2',
            link: 'applications/query/pembayaran-pbb-p2',
          },
          {
            title: 'Simulasi BPHTB',
            link: '#',
          },
          {
            title: 'Riwayat BPHTB',
            link: '#',
          },
          {
            title: 'Status BPHTB',
            link: '#',
          },
        ]
      },
      {
        title: 'Data Wajib Pajak',
        guards: ['kabid', 'kasubbid', 'operator'],
        fontIcon: 'duotone',
        adjustment: [ADJUSTMENT_SERVICE_MENU.BPHTB],
        icon: 'clipboard',
        link: 'applications/data-rekap-wajib-pajak',
        subItems: [
          {
            title: 'Cari Wajib Pajak',
            link: 'applications/data-rekap-wajib-pajak/list-wajib-pajak',
          },
          {
            title: 'Histori Wajib Pajak',
            link: 'applications/data-rekap-wajib-pajak/history-wajib-pajak',
          },
        ],
      },
      {
        title: 'APPLICATIONS BPHTB',
        guards: ['superadmin', 'admin', 'kabid'],
        adjustment: [ADJUSTMENT_SERVICE_MENU.BPHTB],
        link: 'transaction'
      },
      {
        title: 'Edit BPHTB',
        guards: ['kabid', 'kasubbid', 'operator'],
        fontIcon: 'duotone',
        adjustment: [ADJUSTMENT_SERVICE_MENU.BPHTB],
        icon: 'message-edit',
        link: 'applications/edit-bphtb/edit',
      },
      {
        title: 'Pembatalan SSPD',
        guards: ['superadmin', 'admin'],
        fontIcon: 'duotone',
        adjustment: [ADJUSTMENT_SERVICE_MENU.BPHTB],
        icon: 'abstract-49',
        link: 'applications/pembatalan-sppd',
      },
      {
        title: 'Laporan',
        guards: ['superadmin', 'admin', 'kabid'],
        adjustment: [ADJUSTMENT_SERVICE_MENU.BPHTB],
        fontIcon: 'duotone',
        icon: 'graph-4',
        link: 'applications/laporan',
        subItems: [
          {
            title: 'Laporan SSPD',
            link: 'applications/laporan/laporan-pembayaran-notaris',
          },
          {
            title: 'Laporan Pembayaran SSPD',
            link: 'applications/laporan/laporan-notaris',
          },
          {
            title: 'Laporan SKPDKB',
            link: 'applications/laporan/laporan-skpdkb',
          },
        ]
      },

    ],
  },
  {
    guards: ['operator', 'kasubbid'],
    guardSubBidang: ['pendataan & penetapan'],
    adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
    subItems: [
      {
        title: 'Laporan',
        guards: ['kasubbid', 'operator'],
        guardSubBidang: ['pendataan & penetapan'],
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
        fontIcon: 'duotone',
        icon: 'graph-4',
        link: 'applications/laporan',
        subItems: [
          {
            title: 'Laporan SSPD',
            link: 'applications/laporan/laporan-pembayaran-notaris',
          },
          {
            title: 'Laporan Pembayaran SSPD',
            link: 'applications/laporan/laporan-notaris',
          },
          {
            title: 'Laporan SKPDKB',
            link: 'applications/laporan/laporan-skpdkb',
          },
        ]
      }
    ]

  },
]
