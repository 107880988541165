import { IMenu } from "../_menu-config";

export const MasterData: IMenu[] = [
  {
    title: 'MASTER DATA',
    guards: ['superadmin',],
    subItems: [
      {
        title: 'Profile',
        icon: 'profile-circle',
        fontIcon: 'bi-person',
        link: 'crafted/account',
        subItems: [
          {
            title: 'Overview',
            link: 'crafted/account/overview',
          },
          {
            title: 'Settings',
            link: 'crafted/account/settings',
          },
        ],
      },
      {
        title: 'User Management',
        icon: 'address-book',
        fontIcon: 'duotone',
        link: 'master-data/user-management',
        subItems: [
          {
            title: 'List Users',
            link: 'master-data/user-management/list-users',
          },
          {
            title: 'Permission',
            link: 'master-data/user-management/permission',
          },
        ],
      },
      {
        title: 'Menu Management',
        icon: 'menu',
        fontIcon: 'duotone',
        link: 'master-data/menu-management',
        subItems: [
          {
            title: 'Menu Settings',
            link: 'master-data/menu-management/setting-menu',
          },
        ],
      },
      {
        title: 'Bidang',
        icon: 'chart',
        fontIcon: 'duotone',
        link: 'master-data/bidang',
        subItems: [
          {
            title: 'Bidang',
            link: 'master-data/bidang/bidang',
          },
          {
            title: 'Sub Bidang',
            link: 'master-data/bidang/sub-bidang',
          },
        ],
      },
      {
        title: 'Layanan',
        guards: ['admin', 'superadmin'],
        link: 'master-data/layanan',
        fontIcon: 'duotone',
        icon: 'abstract-25',
        subItems: [
          {
            title: 'Jenis Pajak',
            link: 'master-data/layanan/layanan',
          },
          {
            title: 'Jenis Layanan',
            link: 'master-data/layanan/jenis-layanan',
          },
        ],
      },
      {
        title: 'Pbb Minimal',
        guards: ['admin', 'superadmin'],
        fontIcon: 'duotone',
        icon: 'size',
        link: 'master-data/pbb-minimal',
      },
      {
        title: 'Refrensi',
        fontIcon: 'duotone',
        icon: 'cube-2',
        link: 'master-data/refrensi',
        subItems: [
          {
            title: 'Kelas Bumi',
            guards: ['superadmin'],
            link: 'master-data/refrensi/kelas-bumi',
            fontIcon: 'duotone',
            icon: 'cd',
          },
          {
            title: 'Pekerjaan',
            guards: ['superadmin'],
            link: 'master-data/refrensi/pekerjaan',
            fontIcon: 'duotone',
            icon: 'courier',
          },
          {
            title: 'Kecamatan',
            guards: ['superadmin'],
            link: 'master-data/refrensi/kecamatan',
            fontIcon: 'duotone',
            icon: 'route',
          },
          {
            title: 'Kelurahan',
            guards: ['superadmin'],
            link: 'master-data/refrensi/kelurahan',
            fontIcon: 'duotone',
            icon: 'cube-2',
          },
          {
            title: 'Blok',
            guards: ['superadmin'],
            link: 'master-data/refrensi/blok',
            fontIcon: 'duotone',
            icon: 'cube-2',
          },
        ],
      },
    ],
  },
]
