import {FC, Fragment, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon, KTSVG} from 'src/_metronic/helpers'

export type ImagePreviewProps = {
  name?: string
  url?: string
  onError?: React.ReactEventHandler<HTMLImageElement>
  [key: string]: any
}

export const ImagePreview: FC<ImagePreviewProps> = ({url, name, className, ...props}) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  return (
    <Fragment>
      <div
        style={props.style}
        className={`card overlay !flex !items-center overflow-hidden ${className}`}
      >
        <div onClick={() => setShowModal(true)} className='card-body flex items-center p-0'>
          <div className='overlay-wrapper w-100 h-100'>
            <img
              className='w-100 rounded object-cover w-100 h-100'
              src={url}
              alt={''}
              onError={props.onError}
            />
          </div>
          <div className='overlay-layer bg-dark bg-opacity-25'>
            {name ? (
              <span className='text-white badge badge-primary cursor-pointer text-capitalize'>
                {name}
              </span>
            ) : (
              <i className='bi bi-eye-fill text-white fs-3x cursor-pointer '></i>
            )}
          </div>
        </div>
      </div>
      <Modal
        size='lg'
        onHide={() => setShowModal(false)}
        show={showModal}
        backdrop={'static'}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <Modal.Title className='text-capitalize'>{name}</Modal.Title>
          <div onClick={() => setShowModal(false)}>
            <KTIcon
              iconName='cross-square'
              className='text-4xl cursor-pointer hover:text-blue-400'
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <img src={url} alt='showimage' className='w-100' onError={props.onError} />
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}
