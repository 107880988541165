import {ErrorMessage, Field, FieldProps} from 'formik'
import InputMask from 'react-input-mask'
import {FC, Fragment, HTMLInputTypeAttribute, useState} from 'react'
import clsx from 'clsx'
import {KTIcon} from 'src/_metronic/helpers'
import Div from './div'

/**
 * Interface for Text Input.
 */
interface TextInputProps {
  name: string
  value?: string
  mask?: string
  placeholder?: string
  isFormik?: boolean
  withLabel?: boolean
  label?: string
  type?: HTMLInputTypeAttribute | undefined
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  iconName?: string
  [key: string]: any
}

/**
 * Text Input.
 * Custom text input and can add validate yup.
 *
 * @returns {React.ReactElement}
 */
export const TextInput: FC<TextInputProps> = ({
  name,
  label,
  mask,
  onChange,
  placeholder,
  iconName,
  value,
  type,
  isFormik = false,
  ...props
}) => {
  const [currentType, setCurrentType] = useState<string>(type)
  const className: string = clsx('eform--input--control', props?.className)
  const styleName = {
    paddingLeft: '40px',
  }


  const changePasswordPrevToggle = () => {
    if (currentType === "text") setCurrentType("password")
    else setCurrentType("text")
  }


  if (!isFormik) {
    if (mask) {
      return (
        <Div className='d-flex align-items-center position-relative my-1'>
          {iconName && <KTIcon iconName={iconName} className='fs-1 position-absolute ms-4' />}
          <InputMask
            {...props}
            mask={mask}
            name={name}
            style={iconName ? styleName : null}
            value={value}
            onChange={(e) => {
              if (onChange) onChange(e)
            }}
            placeholder={placeholder}
            className={className}
          />
          {
            type === 'password' &&
            (
              <span onClick={changePasswordPrevToggle} className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
              data-kt-password-meter-control="visibility">
                      <i className={clsx("ki-duotone ki-eye-slash fs-1", { "d-none": currentType === "text" })}><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></i>
                      <i className={clsx("ki-duotone ki-eye fs-1", { "d-none": currentType === "password" })}><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>
              </span>
            )
          }
        </Div>
      )
    }

    return (
      <div className='d-flex align-items-center w-100 position-relative my-1'>
        {iconName && <KTIcon iconName={iconName} className='fs-1 position-absolute ms-4' />}
        <input
          {...props}
          type={currentType}
          name={name}
          style={iconName ? styleName : null}
          value={value}
          placeholder={placeholder}
          className={className}
          {...(onChange !== undefined ? {onChange: onChange} : '')}
        />
      </div>
    )
  }

  return (
    <Field name={name} value={value}>
      {({field, form}: FieldProps) => {
        if (mask) {
          return (
            <Div className='flex w-100 items-center relative'>
              {iconName && <KTIcon iconName={iconName} className='fs-1 position-absolute ms-4' />}
              <InputMask
                {...field}
                mask={mask}
                style={iconName ? styleName : null}
                name={name}
                value={field.value}
                onChange={(e) => {
                  form.setFieldValue(field.name, e.target.value)
                  if (onChange) onChange(e)
                }}
                placeholder={placeholder ? placeholder : label ? `Masukan ${label}` : ''}
                className={className}
              />
            </Div>
          )
        }

        return (
          <Fragment>
            <Div className='flex w-100 items-center relative'>
              {iconName && <KTIcon iconName={iconName} className='fs-1 position-absolute ms-4' />}
              <input
                {...field}
                type={currentType}
                style={iconName ? styleName : null}
                name={name}
                value={field.value}
                placeholder={label ? `Masukan ${label}` : ''}
                className={className}
                {...(onChange !== undefined ? {onChange: onChange} : '')}
              />
              {
                type === 'password' &&
                (
                  <span onClick={changePasswordPrevToggle} className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                  data-kt-password-meter-control="visibility">
                          <i className={clsx("ki-duotone ki-eye-slash fs-1", { "d-none": currentType === "text" })}><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></i>
                          <i className={clsx("ki-duotone ki-eye fs-1", { "d-none": currentType === "password" })}><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>
                  </span>
                )
              }
            </Div>
            <Div className='text-danger'>
              <ErrorMessage name={name} />
            </Div>
          </Fragment>
        )
      }}
    </Field>
  )
}
