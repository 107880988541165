import { ResponseServerWithMessage } from 'src/app/types/api'
import { NopSelesai, ParamsPrintMassalMultiple, SPPT, SpptHistory } from './type'
import axios from 'axios'
import { StringHelpers } from 'src/app/helpers/string-helpers'
import { ResponseServerWithMessageStorePembatalanDenda, TypePayloadPembatalanDenda } from 'src/app/modules/applications/pembatalan-denda/type/type-pembatalan-denda'

export abstract class ISpptService {
  static getMassalMultiple(
    payload: ParamsPrintMassalMultiple
  ): Promise<ResponseServerWithMessage<SPPT[]>> {
    throw new Error('Method not implemented.')
  }
}

export class SpptSerice implements ISpptService {
  public static getMassalMultiple = (
    payload: ParamsPrintMassalMultiple
  ): Promise<ResponseServerWithMessage<SPPT[]>> => {
    const endpoint: string = 'print/sppt/masal-multiple'
    const formData: FormData = new FormData()

    formData.append('KD_KECAMATAN', payload.kd_kecamatan)
    formData.append('KD_KELURAHAN', payload.kd_kelurahan)

    for (let i = 0; i < payload.kd_blok.length; i++) {
      formData.append('KD_BLOK[]', StringHelpers.formatInputToNumber(payload.kd_blok[i]))
      formData.append('no_urut_awal[]', StringHelpers.formatInputToNumber(payload.no_urut_awal[i]))
      formData.append('no_urut_akhir[]', StringHelpers.formatInputToNumber(payload.no_urut_akhir[i]))
      formData.append('status_kolektif[]', StringHelpers.formatInputToNumber(payload.status_kolektif[i]))
    }

    return axios.post(endpoint, formData).then((res) => res.data)
  }


  public static publicSearchHistorySPPT = (
    kdKecamatan: string,
    kdKelurahan: string,
    kdBlock: string,
    noUrut: string,
    statusKolektif: string
  ): Promise<ResponseServerWithMessage<SpptHistory>> => {
    return axios
      .get(`public/history/${kdKecamatan}/${kdKelurahan}/${kdBlock}/${noUrut}/${statusKolektif}`)
      .then((res) => res.data)
  }


  public static searchHistorySPPT = (
    kdKecamatan: string,
    kdKelurahan: string,
    kdBlock: string,
    noUrut: string,
    statusKolektif: string
  ): Promise<ResponseServerWithMessage<SpptHistory[]>> => {
    return axios
      .get(`sppt/history/${kdKecamatan}/${kdKelurahan}/${kdBlock}/${noUrut}/${statusKolektif}`)
      .then((res) => res.data)
  }



  /**
   * getNopSelesai.
   * This mehtod usage for handling get nop selesai from remote data server.
   * @returns {Promise<ResponseServerWithMessage<NopSelesai[]>>}
   */
  public static getNopSelesai = (params?: any): Promise<ResponseServerWithMessage<NopSelesai[]>> => {
    let endpoint: string = 'peta-objek-pajak/data'

    if (!params?.district) {
      endpoint += '/all'
    }

    if (params?.district) {
      endpoint += '/' + params?.district
      if (params?.village && params.district !== 'all') {
        endpoint += '/' + params?.village
      }
    }

    return axios.get(endpoint).then(res => res.data)
  }



  /**
   * pembayaranManualStore.
   * This mehtod usage for handling store pemmbayaran manual
   * @param {FormData} params 
   * @returns {Promise<ResponseServerWithMessage<string>>}
   */
  public static pembayaranManualStore = (params: FormData): Promise<ResponseServerWithMessage<string>> => {
    const endpoint: string = 'sppt/pembayaran-manual/store'
    return axios.post(endpoint, params).then(res => res.data)
  }



  /**
   * pembatalanTransaksiStore.
   * This methos will store `pembatalan transaksi`
   * @param {FormData} paylaod 
   * @returns {Promise<ResponseServerWithMessage<string>>}
   */
  public static pembatalanTransaksiStore = (paylaod: FormData): Promise<ResponseServerWithMessage<string>> => {
    const endpoint: string = 'sppt/pembatalan-transaksi/store'
    return axios.post(endpoint, paylaod).then(res => res.data)
  }


  /**
   * Pembatalan Denda.
   * This methos will store `pembatalan denda`
   * @param {FormData} paylaod 
   * @returns {Promise<ResponseServerWithMessage<string>>}
   */
  public static pembatalanDendaStore = (paylaod: FormData): Promise<ResponseServerWithMessageStorePembatalanDenda> => {
    const endpoint: string = 'sppt/pembatalan-denda/store'
    
    return axios.post<ResponseServerWithMessageStorePembatalanDenda>(endpoint, paylaod).then(res => res.data)
  }
}
