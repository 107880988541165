import { ADJUSTMENT_SERVICE_MENU } from '../_data'
import { IMenu } from '../_menu-config'

export const AdminMenuPBB: IMenu[] = [
  {
    guards: ['admin_pbb'],
    guardSubBidang: ['pendataan pbb'],
    adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
    subItems: [
      {
        title: 'Berkas Permohonan',
        guards: ['admin_pbb'],
        link: 'applications/berkas-permohonan',
        fontIcon: 'duotone',
        guardSubBidang: ['pelayanan'],
        icon: 'bank',
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
        subItems: [
          {
            title: 'Semua Permohonan',
            rightTextCodeEl: 'DPMP',
            link: 'applications/application-list/all',
          },
          {
            title: 'Daftar Baru',
            rightTextCodeEl: 'DPMP',
            link: 'applications/application-list/daftar-baru',
          },

          {
            title: 'Perubahan Status NOP',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/status-nop',
          },
          {
            title: 'Pecah NOP ',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/pecah-nop',
          },
          {
            title: 'NOP FASUM ',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/nop-fasum',
          },
          {
            title: 'Update Data Bangunan',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/update-bangunan',
          },
          {
            title: 'Pembatalan SPPT',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/pembatalan-sppt',
          },
          {
            title: 'Mutasi Objek',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/mutasi-objek',
          },
          {
            title: 'Gabung NOP',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/gabung-nop',
          },
        ],
      },
      {
        title: 'Menu Admin',
        guards: ['admin_pbb'],
        link: 'applications/berkas-permohonan',
        fontIcon: 'duotone',
        icon: 'technology-4',
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
        subItems: [
          {
            title: 'Penetapan SPPT',
            fontIcon: 'duotone',
            icon: 'award',
            link: 'applications/penetapan-sppt',
            guards: ['admin_pbb'],
            adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
          },
          {
            title: 'List Penetapan SPPT',
            fontIcon: 'duotone',
            icon: 'questionnaire-tablet',
            link: 'applications/list-penetapan-sppt',
            guards: ['admin_pbb'],
            adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
          },
          {
            title: 'Update NJOP',
            fontIcon: 'duotone',
            icon: 'setting',
            link: 'applications/update-njop',
            guards: ['admin_pbb'],
            adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
          },
          {
            title: 'Pengurangan SPPT',
            fontIcon: 'duotone',
            icon: 'financial-schedule',
            link: 'applications/form-pemotongan-sppt',
            guards: ['admin_pbb'],
            adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
          },
          {
            title: 'List Pengurangan SPPT',
            fontIcon: 'duotone',
            icon: 'clipboard',
            link: 'applications/list-pemotongan-sppt',
            guards: ['admin_pbb'],
            adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
          },
          {
            title: 'Pembatalan Denda',
            fontIcon: 'duotone',
            icon: 'abstract-47',
            link: 'applications/pembatalan-denda',
            guards: ['admin_pbb'],
            guardBidang: ['admin_bidang_empty'],
            adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
          },
          {
            title: 'Pembatalan Pembayaraan',
            guards: ['admin_pbb'],
            adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
            icon: 'devices',
            link: 'applications/pembatalan-pembayaran',
          },
          {
            title: 'Tagihan Kolektor',
            guards: ['admin_pbb'],
            fontIcon: 'duotone',
            guardSubBidang: ['pelayanan', 'pendataan pbb'],
            icon: 'lots-shopping',
            link: 'applications/tagihan-kolektor',
          },
          {
            title: 'Koreksi Pembayaran',
            guards: ['admin_pbb'],
            guardSubBidang: ['pendataan pbb', 'pelayanan'],
            link: 'applications/koreksi-pembayaran',
            icon: 'barcode',
            adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
          },
        ]
      },
      {
        title: 'Pencetakan',
        icon: 'book',
        guardSubBidang: ['pelayanan', 'pendataan pbb'],
        fontIcon: 'duotone',
        link: 'applications/pencetakan',
        guards: ['admin_pbb'],
        subItems: [
          {
            title: 'Cetak SKNJOP',
            link: 'applications/pencetakan/cetak-njop',
          },
          {
            title: 'Cetak STTS',
            link: 'applications/pencetakan/cetak-stts',
          },
          {
            title: 'Pencetakan SPPT',
            link: 'applications/pencetakan/pencetakan-sppt',
          },
          {
            title: 'SPPT Buku 4.5',
            link: 'applications/pencetakan/sppt-buku-45',
          },
          {
            title: 'Cetak Massal',
            link: 'applications/pencetakan/cetak-massal',
          },
        ],
      },
      {
        title: 'Data Wajib Pajak',
        guards: ['admin_pbb'],
        fontIcon: 'duotone',
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
        icon: 'clipboard',
        guardSubBidang: ['pelayanan', 'pendataan pbb'],
        link: 'applications/data-rekap-wajib-pajak',
        subItems: [
          {
            title: 'Cari Wajib Pajak',
            link: 'applications/data-rekap-wajib-pajak/list-wajib-pajak',
          },
          {
            title: 'Histori Wajib Pajak',
            link: 'applications/data-rekap-wajib-pajak/history-wajib-pajak',
          },
        ],
      },

      // {
      //   title: 'Peta Objek Pajak',
      //   guards: ['admin_pbb'],
      //   fontIcon: 'duotone',
      //   guardSubBidang: ['pelayanan', 'pendataan pbb'],
      //   icon: 'map',
      //   link: 'https://smartgis.infotekmetrodata.co.id/',
      // },
      // {
      //   title: 'Pemutahiran',
      //   guards: ['admin_pbb'],
      //   fontIcon: 'duotone',
      //   guardSubBidang: ['pelayanan', 'pendataan pbb'],
      //   icon: 'book-open',
      //   link: 'applications/pemutahiran',
      // },

      {
        title: 'Laporan ',
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
        guards: ['admin_pbb'],
        fontIcon: 'duotone',
        icon: 'graph-4',
        link: 'applications/laporan/laporan-pbb',
        subItems: [
          {
            title: 'Ketetapan',
            link: 'applications/laporan/laporan-pbb/ketetapan',
          },
          {
            title: 'Realisasi',
            link: 'applications/laporan/laporan-pbb/realisasi',
          },
          {
            title: 'Piutang',
            link: 'applications/laporan/laporan-pbb/piutang',
          },
          {
            title: 'DHKP',
            link: 'applications/dhkp',
          },
        ],
      },
    ],
  },
]
