import React from 'react'

interface LoopRendererProps {
  data?: any[]
  loopCount?: number
  className?: string
  children: (item: any, index: number) => React.ReactNode
}

/**
 * Loop Renderer.
 *
 * This component usage for handlign looping rendering compoennt
 * @param {LoopRendererProps} param0
 * @returns {React.ReactElement}
 */
const LoopRenderer: React.FC<LoopRendererProps> = ({data = [], children, className}) => {
  return (
    <React.Fragment>
      <div className={className}>
        {data.map((item, itemIndex) => (
          <React.Fragment key={itemIndex}>{children(item, itemIndex)}</React.Fragment>
        ))}
      </div>
    </React.Fragment>
  )
}

export default LoopRenderer
