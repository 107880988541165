import {Carousel} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import {FC, Fragment} from 'react'
import {useQuery} from 'react-query'
import {fetchData} from '../core/_request'

const HomePage: FC = () => {
  const {data} = useQuery('fetch-slider-home-page', () => fetchData(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  return (
    <Fragment>
      <Carousel
        prevIcon={
          <div className='custom-carousel-control-prev-icon'>
            <KTIcon iconName='left' className='fs-1 text-dark' />
          </div>
        }
        nextIcon={
          <div className='custom-carousel-control-next-icon'>
            <KTIcon iconName='right' className='fs-1 text-dark' />
          </div>
        }
        fade
        indicators={true}
        className='h-100vh overflow-hidden'
        touch={true}
      >
        {data?.data?.map((data, index) => (
          <Carousel.Item key={`caraouser-item-${index + 1}`}>
            <img
              className='d-block w-100 h-100vh object-fit-cover'
              style={{
                filter: 'brightness(90%)',
              }}
              src={data.slider_name}
              alt='kantor-binjai'
            />
            <Carousel.Caption className='position-absolute d-flex flex-column align-items-center'>
              <h1 className='text-white fs-1'>{data.title}</h1>
              <div className='w-75 mt-6'>
                <span className='text-white'>{data.description}</span>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </Fragment>
  )
}

export {HomePage}
