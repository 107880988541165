import {ADJUSTMENT_SERVICE_MENU} from '../_data'
import {IMenu} from '../_menu-config'

export const Logout: IMenu[] = [
  {
    subItems: [
      {
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP, ADJUSTMENT_SERVICE_MENU.BPHTB],
        title: 'Histori Nop',
        icon: 'address-book',
        fontIcon: 'duotone',
        link: 'applications/histori-nop',
      },
      {
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP, ADJUSTMENT_SERVICE_MENU.BPHTB],
        title: 'Sign Out',
        icon: 'exit-left',
        fontIcon: 'duotone',
        link: 'sign-auth',
      },
    ],
  },
]
