import { ReportHandler } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ onCLS, onFID, onLCP, onFCP, onINP, onTTFB }) => {
      onCLS(onPerfEntry)
      onFID(onPerfEntry) 
      onLCP(onPerfEntry) 
      onFCP(onPerfEntry) 
      onINP(onPerfEntry) 
      onTTFB(onPerfEntry)
    });
  }
};

export default reportWebVitals;
