import {FC, forwardRef} from 'react'
import {VariantProps, cva} from 'class-variance-authority'
import {cn} from '../../helpers'

<div className="dark:hover:"></div>

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus:outline-none disabled:opacity-50 disabled:pointer-events-none data-[state=open]:bg-slate-100',
  {
    variants: {
      variant: {
        default: 'bg-blue-500 text-white hover:bg-blue-600',
        'default-light': 'bg-blue-50 text-blue-500 hover:bg-blue-400 hover:text-white',
        destructive: 'bg-red-500 text-white hover:bg-red-600 dark:hover:bg-red-600',
        'destructive-light': 'bg-red-100 text-red-500 hover:bg-red-500 hover:text-white',
        outline:
          'border border-slate-200 hover:text-white dark:border-blue-500 dark:hover:bg-blue-500 dark:text-blue-500',
      },
      size: {
        default: 'h-10 py-2 px-4',
        sm: 'h-9 px-2 rounded-md',
        lg: 'h-11 px-8 rounded-md',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  href?: string
}

const Button: FC<ButtonProps> = forwardRef<HTMLButtonElement, ButtonProps>(
  ({variant, size, className, children, ...props}, ref) => {
    return (
      <button ref={ref} className={cn(buttonVariants({variant}), className)} {...props}>
        {children}
      </button>
    )
  }
)

export {Button}
