import {FC, ReactElement} from 'react'

/**
 * Props for component Conditinal Rendering.
 */
export interface ConditinalRenderingProps {
  onTrueComponent: ReactElement
  onFalseComponent?: ReactElement
  conditional: boolean
}

/**
 * Conditinal Rendering.
 *
 * This component will handling conditinal rendering component
 *
 * @param param0 - Type ConditinalRenderingProps
 * @returns {React.ReactElement}
 */
export const ConditinalRendering: FC<ConditinalRenderingProps> = ({
  conditional,
  onFalseComponent,
  onTrueComponent,
}) => {
  if (conditional) {
    return onTrueComponent
  }

  if (onFalseComponent) return onFalseComponent
  return null
}
