import {ADJUSTMENT_SERVICE_MENU} from '../_data'
import {IMenu} from '../_menu-config'

export const AdminMenu: IMenu[] = [
  {
    title: 'Applications BPHTB',
    guards: ['kabid', 'admin', 'operator'],
    // guardSubBidang: ['pendataan & penetapan'],
    guardBidang: ['admin_bidang_empty'],
    subItems: [
      // just for admin without bidang
      {
        title: 'PPAT/NOTARIS',
        fontIcon: 'duotone',
        icon: 'people',
        link: 'master-data/ppat-notaris',
        guards: ['admin'],
        guardBidang: ['admin_bidang_empty'],
      },
      {
        title: 'Edit BPHTB',
        fontIcon: 'duotone',
        icon: 'message-edit',
        link: 'applications/edit-bphtb/edit',
        guards: ['admin'],
        guardBidang: ['admin_bidang_empty'],
      },
      {
        title: 'Edit Status Verifikasi',
        fontIcon: 'status',
        icon: 'sun',
        link: 'applications/edit-status-verifikasi',
        guards: ['admin'],
        guardBidang: ['admin_bidang_empty'],
      },
      {
        title: 'Daftar SSPD',
        guards: ['notaris'],
        fontIcon: 'duotone',
        icon: 'financial-schedule',
        guardBidang: ['admin_bidang_empty'],
        link: 'applications/daftar-sppd/permohonan-masuk',
      },
      {
        title: 'List Pembatalan SSPD',
        guards: ['notaris', 'kabid', 'admin', 'operator'],
        fontIcon: 'duotone',
        guardBidang: ['admin_bidang_empty'],
        icon: 'eraser',
        link: 'applications/list-pembatalan-sppd',
      },
      {
        title: 'Pembatalan SSPD',
        guards: ['superadmin', 'admin'],
        fontIcon: 'duotone',
        guardBidang: ['admin_bidang_empty'],
        icon: 'abstract-49',
        link: 'applications/pembatalan-sspd',
      },
      {
        title: 'Query',
        guards: ['admin'],
        fontIcon: 'duotone',
        guardBidang: ['admin_bidang_empty'],
        icon: 'questionnaire-tablet',
        link: '#',
        subItems: [
          {
            title: 'Simulasi BPHTB',
            link: '#',
          },
          {
            title: 'Riwayat BPHTB',
            link: '#',
          },
          {
            title: 'Status BPHTB',
            link: '#',
          },
        ],
      },
      // {
      //   title: 'Histori Wajib Pajak',
      //   guards: ['admin'],
      //   fontIcon: 'duotone',
      //   guardBidang: ['admin_bidang_empty'],
      //   icon: 'clipboard',
      //   link: 'applications/data-rekap-wajib-pajak/history-wajib-pajak',
      // },
      {
        title: 'Laporan',
        guards: ['superadmin', 'admin'],
        fontIcon: 'duotone',
        icon: 'graph-4',
        guardBidang: ['admin_bidang_empty'],
        link: 'applications/laporan',
        subItems: [
          {
            title: 'Laporan SSPD',
            link: 'applications/laporan/laporan-pembayaran-notaris',
          },
          {
            title: 'Laporan Pembayaran SSPD',
            link: 'applications/laporan/laporan-notaris',
          },
          {
            title: 'Laporan SKPDKB',
            link: 'applications/laporan/laporan-skpdkb',
          },
        ],
      },
      {
        title: 'SSPD',
        fontIcon: 'duotone',
        icon: 'financial-schedule',
        link: 'transaction/sspd',
        guards: ['admin'],
        guardBidang: ['admin_bidang_empty'],
        subItems: [
          {
            title: 'Daftar SSPD',
            link: 'transaction/sspd/daftar-sppd',
          },
          {
            title: 'SSPD ke BPN',
            link: 'transaction/sspd/sspd-ke-bpn',
          },
        ],
      },
      {
        title: 'SKPDKB',
        fontIcon: 'duotone',
        icon: 'finance-calculator',
        link: 'transaction/skpdkb',
        guards: ['admin'],
        guardBidang: ['admin_bidang_empty'],
        subItems: [
          {
            title: 'Daftar SKPDKB',
            link: 'transaction/skpdkb/list-skpdkb',
          },
          {
            title: 'Pembayaran SKPDKB',
            link: '#',
          },
        ],
      },
    ],
  },
  {
    title: 'Applications Pbb',
    guards: ['admin'],
    guardSubBidang: ['pendataan & penetapan'],
    adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
    subItems: [
      {
        title: 'Laporan',
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
        guards: ['operator', 'kabid', 'kasubbid', 'admin', 'superadmin'],
        fontIcon: 'duotone',
        icon: 'graph-4',
        link: 'applications/laporan/laporan-pbb',
        subItems: [
          {
            title: 'Ketetapan',
            link: 'applications/laporan/laporan-pbb/ketetapan',
          },
          {
            title: 'Realisasi',
            link: 'applications/laporan/laporan-pbb/realisasi',
          },
          {
            title: 'Piutang',
            link: 'applications/laporan/laporan-pbb/piutang',
          },
        ],
      },
      {
        title: 'Data Rekap Wajib Pajak',
        guards: ['kasubbid', 'admin'],
        fontIcon: 'duotone',
        icon: 'clipboard',
        link: 'applications/data-rekap-wajib-pajak',
        subItems: [
          {
            title: 'Cari Wajib Pajak',
            link: 'applications/data-rekap-wajib-pajak/list-wajib-pajak',
          },
          {
            title: 'Histori Wajib Pajak',
            link: 'applications/data-rekap-wajib-pajak/history-wajib-pajak',
          },
        ],
      },
    ],
  },
  {
    title: 'Applications Pbb',
    guards: ['admin'],
    guardSubBidang: ['pelayanan', 'pendataan pbb'],
    guardBidang: ['admin_bidang_empty'],
    adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
    subItems: [
      {
        title: 'Daftar Permohonan',
        guards: ['admin'],
        guardBidang: ['admin_bidang_empty'],
        icon: 'scroll',
        link: 'applications/application-list',
        fontIcon: 'duotone',
        subItems: [
          {
            title: 'Daftar Baru',
            rightTextCodeEl: 'DPMP',
            link: 'applications/application-list/all',
          },
          {
            title: 'Proses',
            rightTextCodeEl: 'DPPDP',
            link: 'applications/application-list/process',
          },
          {
            title: 'Dibatalkan',
            rightTextCodeEl: 'DPD',
            link: 'applications/application-list/reject',
          },
          {
            title: 'Selesai',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/selesai',
          },
          {
            title: 'Perubahan Status NOP',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/status-nop',
          },
          {
            title: 'Pecah NOP ',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/pecah-nop',
          },
          {
            title: 'NOP FASUM ',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/nop-fasum',
          },
          {
            title: 'Update Data Bangunan',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/update-bangunan',
          },
          {
            title: 'Pembatalan SPPT',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/pembatalan-sppt',
          },
          {
            title: 'Mutasi Objek',
            rightTextCodeEl: 'DPS',
            link: 'applications/application-list/mutasi-objek',
          },
        ],
      },
      {
        title: 'Penetapan SPPT',
        fontIcon: 'duotone',
        icon: 'award',
        link: 'applications/penetapan-sppt',
        guards: ['admin', 'superadmin'],
        guardBidang: ['admin_bidang_empty'],
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
      },
      {
        title: 'List Penetapan SPPT',
        fontIcon: 'duotone',
        icon: 'questionnaire-tablet',
        link: 'applications/list-penetapan-sppt',
        guards: ['admin', 'superadmin'],
        guardBidang: ['admin_bidang_empty'],
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
      },
      {
        title: 'Update NJOP',
        fontIcon: 'duotone',
        icon: 'setting',
        link: 'applications/update-njop',
        guards: ['admin', 'superadmin'],
        guardBidang: ['admin_bidang_empty'],
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
      },
      {
        title: 'Pengurangan SPPT',
        fontIcon: 'duotone',
        icon: 'financial-schedule',
        link: 'applications/form-pemotongan-sppt',
        guards: ['admin', 'superadmin'],
        guardBidang: ['admin_bidang_empty'],
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
      },
      {
        title: 'List Pengurangan SPPT',
        fontIcon: 'duotone',
        icon: 'clipboard',
        link: 'applications/list-pemotongan-sppt',
        guards: ['admin', 'superadmin'],
        guardBidang: ['admin_bidang_empty'],
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
      },
      {
        title: 'Koreksi Pembayaran',
        guards: ['admin'],
        guardBidang: ['admin_bidang_empty'],
        link: 'applications/koreksi-pembayaran',
        icon: 'barcode',
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
      },
      {
        title: 'List Koreksi Pembayaran',
        guards: ['admin'],
        guardBidang: ['admin_bidang_empty'],
        link: 'applications/list-koreksi-pembayaran',
        icon: 'book',
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
      },
      {
        title: 'Pembatalan Pembayaraan',
        guards: ['admin'],
        guardBidang: ['admin_bidang_empty'],
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
        icon: 'devices',
        link: 'applications/pembatalan-pembayaran',
      },
      {
        title: 'Pembatalan Denda',
        guards: ['admin'],
        guardBidang: ['admin_bidang_empty'],
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
        icon: 'tablet-up',
        link: 'applications/pembatalan-denda',
      },
      {
        title: 'List Pembatalan Denda',
        guards: ['admin'],
        guardBidang: ['admin_bidang_empty'],
        adjustment: [ADJUSTMENT_SERVICE_MENU.PBBPP],
        icon: 'tablet-book',
        link: 'applications/list-pembatalan-denda',
      },
      // just for admin without sub bidang
      {
        title: 'Pencetakan',
        guardBidang: ['admin_bidang_empty'],
        icon: 'book',
        guards: ['admin'],
        fontIcon: 'duotone',
        link: 'applications/pencetakan',
        subItems: [
          {
            title: 'Cetak SKNJOP',
            link: 'applications/pencetakan/cetak-njop',
          },
          {
            title: 'Cetak STTS',
            link: 'applications/pencetakan/cetak-stts',
          },
          {
            title: 'Pencetakan SPPT',
            link: 'applications/pencetakan/pencetakan-sppt',
          },
          {
            title: 'SPPT Buku 4.5',
            link: 'applications/pencetakan/sppt-buku-45',
          },
          {
            title: 'Cetak Massal',
            link: 'applications/pencetakan/cetak-massal',
          },
        ],
      },
      {
        title: 'Data Rekap Wajib Pajak',
        fontIcon: 'duotone',
        guardBidang: ['admin_bidang_empty'],
        guards: ['admin'],
        icon: 'clipboard',
        link: 'applications/data-rekap-wajib-pajak',
        subItems: [
          {
            title: 'Cari Wajib Pajak',
            link: 'applications/data-rekap-wajib-pajak/list-wajib-pajak',
          },
          {
            title: 'Histori Wajib Pajak',
            link: 'applications/data-rekap-wajib-pajak/history-wajib-pajak',
          },
        ],
      },
      {
        title: 'Peta Objek Pajak',
        guardBidang: ['admin_bidang_empty'],
        fontIcon: 'duotone',
        icon: 'map',
        link: 'applications/peta-objek-pajak',
        guards: ['admin'],
      },
      {
        title: 'Tagihan Kolektor',
        fontIcon: 'duotone',
        guardBidang: ['admin_bidang_empty'],
        icon: 'lots-shopping',
        link: 'applications/tagihan-kolektor',
        guards: ['admin'],
      },
      {
        title: 'Laporan',
        guards: ['admin'],
        fontIcon: 'duotone',
        guardBidang: ['admin_bidang_empty'],
        icon: 'graph-4',
        link: 'applications/laporan/laporan-pbb',
        subItems: [
          {
            title: 'Ketetapan',
            link: 'applications/laporan/laporan-pbb/ketetapan',
          },
          {
            title: 'Realisasi',
            link: 'applications/laporan/laporan-pbb/realisasi',
          },
          {
            title: 'Piutang',
            link: 'applications/laporan/laporan-pbb/piutang',
          },
        ],
      },
      {
        title: 'DHKP',
        fontIcon: 'duotone',
        icon: 'financial-schedule',
        guardBidang: ['admin_bidang_empty'],
        guards: ['admin'],
        link: 'applications/dhkp',
      },
    ],
  },
]
