import { Typography } from "@mui/material";
import { FC, useState } from "react";
import { Image, Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "src/_metronic/helpers";
import { Button } from "src/app/widgets/atomic/buttom";
import { Devider } from "src/app/widgets/atomic/devider";
import Div from "src/app/widgets/atomic/div";
import { TextInput } from "src/app/widgets/atomic/text-input";


interface CekPembayaranPbbProps {
  isOpen: boolean
  isOpenToggle: Function
}


const CekPembayaranPbb: FC<CekPembayaranPbbProps> = ({ isOpenToggle,isOpen }) => {
  const [nop, setNop] = useState<string>('')


  const onSubmitHandler = () => {
    // navigate('cek-pembayaran-pbb', {
    //   state: nop
    // })
    const queryParams = new URLSearchParams({ nop: nop.slice(6) }).toString()
    window.open(`cek-pembayaran-pbb?${queryParams}`, '_blank');
  }


  return (
    <Modal size="sm" centered show={isOpen}>
      <Modal.Body className="text-right">
        <Div className="w-100 flex flex-col justify-center items-center">
          <Image src={toAbsoluteUrl('/media/home/logo-binjai.png')} className="w-[100px]" />
          <Devider />
          <Typography fontWeight="bold" variant="h5">Cek Pembayaran PBB-2</Typography>
          <Div className="text-left mt-5">
            <label className="font-semibold">Masukan Nomor Objek Pajak</label>
            <TextInput onChange={(e) => setNop(e.target.value)} name="nop" mask="12.76.999.999.999.9999.9" isFormik={false} placeholder="Nomor Objek Pajak" />
            <Button onClick={onSubmitHandler} className="w-100 mt-5">Cari</Button>
            <Button variant="outline" className="w-100 mt-3" onClick={() => isOpenToggle()}>Batal</Button>
          </Div>
        </Div>
      </Modal.Body>
    </Modal>
  )
}

export default CekPembayaranPbb