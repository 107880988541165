/* eslint-disable array-callback-return */
import {FC, Fragment, memo} from 'react'
import {SidebarMenuItem} from '../../SidebarMenuItem'
import {SidebarMenuItemWithSub} from '../../SidebarMenuItemWithSub'
import {IMenu} from 'src/_metronic/layout/components/core/_menu-config'
import {ConditinalRendering} from 'src/app/widgets/molecule/conditional-rendering'

interface MenuProps {
  data: IMenu
  guards: string
  title?: string
  adjustment?: string[]
  menuOperator: string
}

export const Menu: FC<MenuProps> = memo(({data, guards, menuOperator, adjustment, title}) => {

  const checkGuardProtection = (role: string, guards?: string[]): boolean => {
    if (!guards) return true
    return guards.includes(role)
  }

  return (
    <Fragment>
      <ConditinalRendering
        conditional={checkGuardProtection(guards, data.guards)}
        onTrueComponent={
          <Fragment>
            <ConditinalRendering
              conditional={title !== undefined && adjustment?.includes(menuOperator)}
              onTrueComponent={
                <Fragment>
                  <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                      <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                        {title}
                      </span>
                    </div>
                  </div>
                </Fragment>
              }
            />
            {(() =>
              data.subItems?.map((element) => {
                if (
                  checkGuardProtection(guards, element.guards) &&
                  element.adjustment?.includes(menuOperator)
                ) {
                  return (
                    <ConditinalRendering
                      key={crypto.randomUUID()}
                      conditional={!element.subItems}
                      onTrueComponent={
                        <SidebarMenuItem
                          to={element.link!}
                          icon={element.icon}
                          title={
                            element.calculation !== undefined
                              ? element.calculation!(guards)
                              : element.title ?? ''
                          }
                          fontIcon={element.fontIcon}
                        />
                      }
                      onFalseComponent={
                        <SidebarMenuItemWithSub
                          key={crypto.randomUUID()}
                          to={element.link!}
                          title={element.title!}
                          fontIcon={element.fontIcon}
                          icon={element.icon}
                          isVisible={element.isVisible ?? false}
                        >
                          {element.subItems?.map((child: any) => (
                            <SidebarMenuItem
                              key={crypto.randomUUID()}
                              to={child.link ?? ''}
                              rightText={child.rightTextCodeEl}
                              title={child.nama_menu ?? child.title ?? ''}
                              hasBullet={true}
                            />
                          ))}
                        </SidebarMenuItemWithSub>
                      }
                    />
                  )
                }
              }))()}
          </Fragment>
        }
      />
    </Fragment>
  )
})
