import {ResponseServerWithMessage} from 'src/app/types/api'
import axios from 'axios'
import { StoreSubBidang, SubBidang, UpdateSubBidang } from './type'

interface IBidangServiceInstance {
  list(queryRequest: object): Promise<ResponseServerWithMessage<SubBidang[]>>
  get(id: string): Promise<ResponseServerWithMessage<SubBidang>>
  store(payload: StoreSubBidang): Promise<ResponseServerWithMessage<string>>
  update(id: string, payload: UpdateSubBidang): Promise<ResponseServerWithMessage<string>> 
  delete(id: string): Promise<ResponseServerWithMessage<string>>
}

class ISubBidangServiceClass {
  static SUB_BIDANG_OPERATOR_PBB = 'pelayanan'
  static SUB_BIDANG_OPERATOR_BPHTB = 'pendataan & penetapan'
  static SUB_BIDANG_OPERATOR_PBB_2 = 'pendataan pbb'

  public static list = (queryRequest: object) => {
    const endpoint: string = 'sub-bidang/data'
    return axios.get(endpoint, {params: queryRequest}).then((res) => res.data)
  }

  public static get = (id: string) => {
    const endpoint: string = 'sub-bidang/get/' + id
    return axios.get(endpoint).then((res) => res.data)
  }

  public static store = (payload: StoreSubBidang) => {
    const endpoint: string = 'sub-bidang/store'
    return axios.post(endpoint, payload).then((res) => res.data)
  }

  public static update = (id: string, payload: UpdateSubBidang) => {
    const endpoint: string = 'sub-bidang/update/' + id
    return axios.put(endpoint, payload).then((res) => res.data)
  }

  public static delete = (id: string) => {
    const endpoint: string = 'sub-bidang/delete/' + id
    return axios.delete(endpoint).then((res) => res.data)
  }
}

const ISubBidangService: IBidangServiceInstance & typeof ISubBidangServiceClass = ISubBidangServiceClass

export default ISubBidangService
